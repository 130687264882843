import { ColorBlue, ColorDark, ColorLight } from '@uangcermat/uikit-web';
import { ColorSchemeInterface } from '@uangcermat/uikit-web/build/src/styles/products/productColorScheme.type';

export const gardakuColorScheme: ColorSchemeInterface = {
  sidebar: {
    background: ColorLight.whiteSolid,
    menuItem: {
      parent: {
        text: {
          default: ColorDark.bermudaGray,
          active: ColorLight.whiteSolid
        },
        icon: {
          default: ColorDark.bermudaGray,
          active: ColorLight.whiteSolid
        }
      },
      child: {
        text: {
          default: ColorDark.gumbo,
          active: ColorDark.blackCoral
        }
      }
    },
    collapsableMenu: {
      background: ColorLight.whiteSolid,
      active: ColorBlue.darkBlue,
      hover: {
        default: ColorDark.solitude,
        active: ColorBlue.darkBlue
      },
      icon: {
        default: ColorDark.bermudaGray,
        active: ColorLight.whiteSolid
      }
    },
    subMenuItem: {
      default: ColorLight.whiteSolid,
      active: ColorLight.aliceBlue,
      hover: {
        background: ColorLight.aliceBlue
      }
    },
    subMenuIndicator: {
      default: ColorBlue.cornFlowerBlue
    },
    footer: {
      borderRight: ColorDark.gumbo,
      text: {
        color: ColorDark.gumbo
      }
    }
  },
  checkPicker: {
    selectedList: ColorDark.blackCoral,
    count: ColorBlue.buttonBlue,
    hoverColor: ColorLight.aliceBlue,
    activeColor: ColorLight.whiteSmoke,
    checkBoxColor: ColorBlue.buttonBlue
  },
  selectPicker: {
    hoverColor: ColorLight.whiteSmoke,
    activeColor: ColorLight.whiteSmoke,
    addButtonColor: ColorLight.whiteSmoke
  }
};
